crminfo = require('./crminfo.staging').default
crminfo.templateMessages = 'templateMessages.at';
crminfo.isAwesomeTutors = true
crminfo.enableTags = true
crminfo.apiHostSmsTool = 'https://connect-at-stage.dsh-agency.com'
crminfo.baseUrlWFH = 'https://connect-stage.dsh-agency.com'
crminfo.showOfferSubscription = false;


defaultConfig = require('./default-config')({
  domain: 'connect-at-stage.dsh-agency.com',
  protocol: 'https',
  sentry: false
  updateNotification: true,
  crmUrl: 'https://a4.dsh-agency.com/api/v1/crminfo'
  crmUrlA5: 'https://api-stage.tn-company.com/api/v3'
  GMTOffset: '+0300'
  crmPluginEnabled: true
  oauthEnabled: false
  authApiHost: 'https://oauth-stage.dsh-agency.com'
  authBackApiHost: 'https://auth-back-stage.dsh-agency.com'
  fileServerUrl: 'https://n4bybpe0o7.execute-api.eu-west-1.amazonaws.com/dev'
  mqttUrl: 'wss://connect-at-stage.dsh-agency.com:8084/mqtt'
  fileSharingLink: 'https://awesome-tutors-stage.space'
  crminfo: crminfo
  dashboardTags: true
  analyticByChannelType: true
  xmpp: false
  showRankingRules: true
  isAwesomeTutors:  true
  showTotalActiveConversationsSales: false
})

module.exports = _.extend defaultConfig, {}
